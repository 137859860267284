import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { TabButton } from './styled-components';
import { TabsContext } from './Tabs';

export const Tab = ({ className, children, to, as, activeStyles, ...props }) => {
  const { activeTab, setActiveTab } = useContext(TabsContext);
  const onClick = () => setActiveTab(to);

  return (
    <TabButton
      className={className}
      as={as}
      data-analytics-tab={to}
      $isActive={activeTab === to}
      onClick={onClick}
      type="button"
      $activeStyles={activeStyles}
      {...props}
    >
      {children}
    </TabButton>
  );
};

Tab.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  activeStyles: PropTypes.arrayOf(PropTypes.string),
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
};
