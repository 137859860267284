import React from 'react';

import { Button, ButtonTypeEnum } from '@uc-common/button';

import { Link } from '~/src/components/Link';
import { LinkWithArrow } from '~/src/components/Typography';

import {
  ReleasingCardContainer,
  ReleasingCardTitle,
  ReleasingCardText,
  ReleasingCardItem,
  ReleasingCardItemIcon,
  ReleasingCardItemTitle,
  ReleasingCardItemText,
  ReleasingCardButtonsContainer,
} from '../styles/ReleasingScreen.styles';

export const ReleasingCard = ({ isDark = false }) => (
  <ReleasingCardContainer $isDark={isDark}>
    <ReleasingCardItem>
      <ReleasingCardTitle>Releasing to Production?</ReleasingCardTitle>
      <ReleasingCardText>
        When it’s time to deploy your project to production, Uploadcare provides the tools you need
        to ensure optimal performance, security, and scalability. Fine-tune your file-uploading
        solution for production environments.
      </ReleasingCardText>
    </ReleasingCardItem>

    <ReleasingCardItem>
      <ReleasingCardItemIcon uuid="df80a13c-2b65-4626-89f1-8dc3b8b4ac74" />
      <ReleasingCardItemTitle>No Hidden Costs Inclusive of Storage & CDN.</ReleasingCardItemTitle>
      <ReleasingCardItemText>
        Uploadcare’s File Upload Library comes pre-integrated with cloud-based file storage,
        hosting, and a global CDN.
      </ReleasingCardItemText>
      <LinkWithArrow to="/features/" data-analytics="btn_releasing-card_learn-more">
        Learn more
      </LinkWithArrow>
    </ReleasingCardItem>

    <ReleasingCardItem>
      <ReleasingCardItemIcon uuid="8048ad3b-6edd-47c0-9682-a5bac5015c7f" />
      <ReleasingCardItemTitle>Hassle-free Infrastructure.</ReleasingCardItemTitle>
      <ReleasingCardItemText>
        Forget about configuring servers, buckets, security policies, or roles – all you need is
        Uploadcare’s File Upload Library and an API key.
      </ReleasingCardItemText>
      <LinkWithArrow to="/accounts/signup" data-analytics="btn_signup_1">
        Get an API key
      </LinkWithArrow>
    </ReleasingCardItem>

    <ReleasingCardButtonsContainer>
      <Button
        as={Link}
        to="/accounts/signup/"
        type={ButtonTypeEnum.PRIMARY}
        data-analytics="btn_signup_2"
      >
        Get API keys
      </Button>

      <Button
        as={Link}
        to="mailto:sales@uploadcare.com"
        data-analytics="btn_releasing-card_contact-us"
        type={ButtonTypeEnum.PRIMARY}
        ghost
      >
        Contact us
      </Button>
    </ReleasingCardButtonsContainer>
  </ReleasingCardContainer>
);
