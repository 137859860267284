import React from 'react';

import { ButtonTypeEnum } from '@uc-common/button';

import { Link } from '~/src/components/Link';
import {
  TryCodeBlockContainer,
  TryCodeBlockSuperHeading,
  TryCodeBlockText,
  TryCodeBlockButton,
} from './TryCodeBlock.styles';

export const TryCodeBlock = () => (
  <TryCodeBlockContainer>
    <TryCodeBlockSuperHeading>That’s it!</TryCodeBlockSuperHeading>
    <TryCodeBlockText>
      You now have everything you need to implement an end-to-end file upload in JavaScript!
    </TryCodeBlockText>

    <TryCodeBlockButton
      forwardedAs={Link}
      to="https://codesandbox.io/s/upload-client-gps5be?file=/src/index.js"
      type={ButtonTypeEnum.PRIMARY}
      target="_blank"
      data-analytics="btn_get-started-screen_try-code-example"
    >
      Try in Live Code Editor
    </TryCodeBlockButton>
  </TryCodeBlockContainer>
);
