import React from 'react';
import { Quote } from '~/src/mdx/components/Quote/Quote';
import {
  JsUploadSection,
  JsUploadSubTitle,
  JsUploadSubText,
  JsUploadInlineLink,
} from '../styles/styled-components';
import { CasesScreenStoryBreak } from './styles/CasesScreen.styles';
import { SectionWrapper } from '../../CommonStyles';

export const CasesScreen = () => (
  <JsUploadSection>
    <SectionWrapper>
      <JsUploadSubTitle>Cases</JsUploadSubTitle>
      <JsUploadSubText>
        <JsUploadInlineLink to="/customers/upscribe/">Discover</JsUploadInlineLink> how a solo
        founder implemented file uploading in an hour.
      </JsUploadSubText>

      <CasesScreenStoryBreak vertical="35" visual="light">
        <Quote big author="Joshua Anderton, founder" avatar="0cabdd10-70a0-47ce-a756-ae520be3207c">
          Initially, the implementation took about an hour. I integrate Uploadcare with all of my
          side projects now, and it takes minutes, especially with a Ruby on Rails site. Add the
          gem, place the helpers in the template, and I’m going. Today, it&nbsp;would probably take
          10 to 20 minutes.
        </Quote>
      </CasesScreenStoryBreak>
    </SectionWrapper>
  </JsUploadSection>
);
