import React, { useState, useRef } from 'react';
import { useCurrentBreakpoint, BreakpointEnum } from '@uc-common/use-current-breakpoint';
import { Tabs, Panel } from '@uc-common/tabs';

import {
  APIReferencesContainer,
  APIReferencesLanguages,
  APIReferencesLanguage,
  APIReferencesContent,
  APIReferencesLanguagesExpand,
  ApiReferencesCodeHeading,
  APIReferencesCodeContainer,
  APIReferencesCode,
  APIReferencesLinkContainer,
  APIReferencesLink,
  tabActiveStyles,
} from './styles/APIReferences.styles';

import { MobileExpandButton } from './components/MobileExpandButton';

export const APIReferences = ({ className, references = [], link }) => {
  const defaultReference = references[0] ?? {};

  const [activeReference, setActiveReference] = useState(defaultReference);
  const [isExpanded, setIsExpanded] = useState(false);

  const currentBreakpoint = useCurrentBreakpoint();
  const isMobile = currentBreakpoint === BreakpointEnum.XS;
  const ref = useRef(null);
  const codeContainer = useRef(null);

  const resetContentScroll = () => {
    codeContainer.current.scrollTop = 0;
  };

  const handleClick = (reference) => {
    setActiveReference(reference);
    isMobile ? setIsExpanded(false) : resetContentScroll();
  };

  return (
    <APIReferencesContainer className={className}>
      <Tabs tab={activeReference.id}>
        <APIReferencesLanguages ref={ref}>
          {isMobile && (
            <MobileExpandButton
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
              icon={activeReference.icon}
              contentRef={ref}
            >
              {activeReference.title}
            </MobileExpandButton>
          )}
          <APIReferencesLanguagesExpand $isExpanded={isExpanded}>
            {references.map(({ id, title, icon }) => (
              <APIReferencesLanguage
                key={id}
                to={id}
                activeStyles={tabActiveStyles}
                onClick={() => handleClick({ id, title, icon })}
                $iconUUID={icon}
              >
                {title}
              </APIReferencesLanguage>
            ))}
          </APIReferencesLanguagesExpand>
        </APIReferencesLanguages>
        <APIReferencesContent ref={codeContainer}>
          {references.map(({ id, content }) => (
            <Panel key={id} tab={id}>
              <APIReferencesCodeContainer>
                <ApiReferencesCodeHeading>Code</ApiReferencesCodeHeading>
                <APIReferencesCode
                  language={content.request.language}
                  code={content.request.code}
                />
              </APIReferencesCodeContainer>

              <APIReferencesCodeContainer>
                <ApiReferencesCodeHeading>Result</ApiReferencesCodeHeading>
                <APIReferencesCode
                  language={content.response.language ?? 'json'}
                  code={content.response.code}
                />
              </APIReferencesCodeContainer>
            </Panel>
          ))}
        </APIReferencesContent>
      </Tabs>
      {Boolean(link) && (
        <APIReferencesLinkContainer>
          <APIReferencesLink to={link.href} target="_blank">
            {link.text}
          </APIReferencesLink>
        </APIReferencesLinkContainer>
      )}
    </APIReferencesContainer>
  );
};
