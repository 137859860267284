import React from 'react';

import { Button, ButtonTypeEnum } from '@uc-common/button';

import { SectionWrapper } from '../../CommonStyles';
import { LinkWithArrow } from '../../Typography';
import { Link } from '../../Link';
import {
  FirstScreenSection,
  FirstScreenHeading,
  FirstScreenParagraph,
  FirstScreenButtons,
} from './styles/FirstScreen.styles';

export const FirstScreen = ({ ppc = false }) => (
  <FirstScreenSection data-navbar-theme="dark">
    <SectionWrapper>
      <FirstScreenHeading>JavaScript File Upload Library</FirstScreenHeading>
      <FirstScreenParagraph>
        Enable rock-solid and secure uploads at lightspeed. Integrate a powerful, secure, and
        customizable file uploader into your web application with Uploadcare’s JavaScript File
        Upload Library. Boost your project’s performance and enhance user experience with our
        easy-to-use, feature-rich solution.
      </FirstScreenParagraph>

      <FirstScreenButtons>
        <Button
          as={Link}
          to="/accounts/signup/"
          data-analytics="btn_signup_0"
          type={ButtonTypeEnum.PRIMARY}
        >
          Get started
        </Button>
        {!ppc && (
          <LinkWithArrow to="/schedule-demo/" data-analytics="btn_schedule-demo_0">
            Schedule demo
          </LinkWithArrow>
        )}
      </FirstScreenButtons>
    </SectionWrapper>
  </FirstScreenSection>
);
