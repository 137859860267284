import styled, { css } from 'styled-components';

export const MoreInfoScreenHeadingContainer = styled.div(
  ({ theme: { tablet } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    ${tablet()} {
      flex-direction: row;
      align-items: center;
    }
  `
);

export const MoreInfoScreenSmallTitle = styled.span(
  ({ theme: { tablet, laptop }, $isPurple = false, $isBlue = false }) => css`
    font-weight: 700;
    font-size: 12px;
    line-height: 130%;
    text-transform: uppercase;
    background-size: 100%;
    margin-right: 15px;
    margin-bottom: 5px;

    ${$isPurple &&
    css`
      background: radial-gradient(141.01% 172.5% at 0% 0%, #4924b1 0%, #5ca1ff 84.45%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `}

    ${$isBlue &&
    css`
      background: radial-gradient(141.01% 172.5% at 0% 0%, #371b88 0%, #9365fd 84.45%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `}


    ${tablet()} {
      font-size: 14px;
      margin-bottom: 0px;
    }

    ${laptop()} {
      font-size: 18px;
    }
  `
);

export const MoreInfoScreenListItemContainer = styled.div(
  ({ theme: { desktop } }) => css`
    display: grid;
    grid-gap: 10px;

    ${desktop()} {
      grid-gap: 15px;
    }
  `
);

export const MoreInfoScreenListItemParagraph = styled.div(
  ({ theme: { laptop, desktop } }) => css`
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    color: #93a0ae;
    letter-spacing: -0.025em;
    display: block;
    font-weight: 300;

    ${laptop()} {
      font-size: 18px;
    }

    ${desktop()} {
      font-size: 20px;
    }
  `
);
