import { BuzzLogoIdEnum, logos } from '@uc-common/buzz-logo';

export const CASES = [
  {
    logo: {
      id: logos[BuzzLogoIdEnum.CHATRA_COLOR].src,
      width: '98px',
    },
    companyName: 'Chatra',
    href: '/customers/chatra/',
    paragraph:
      '“In-house file infrastructure? No thank you. With Uploadcare, we save 40 hours of ' +
      'per week and decrease the total cost of ownership (TCO) on infrastructure development. More to ask?”',
    client: {
      photo: 'https://ucarecdn.com/8285d910-1569-4588-b9e1-4bea2c85aa4a/yakovkurda.jpg',
      name: 'Yakov Karda',
      position: 'CEO & co-founder',
    },
  },
  {
    logo: {
      id: logos[BuzzLogoIdEnum.SHOGUN_COLOR].src,
    },
    companyName: 'Shogun',
    href: '/customers/shogun/',
    paragraph:
      '“For us to build the functionality we use, it would probably take a team of 3 or 4 developers several ' +
      "months. Using Uploadcare, we've basically taken a shortcut. We haven't had to build any uploading or " +
      "image-processing infrastructure ourselves, so we've saved a bunch of dev work. This part of our software " +
      "is just handled. It's not something we need to worry about.”",
    client: {
      photo: 'https://ucarecdn.com/457a940e-ba8d-47f3-9496-7d856d2fd193/finbarrtaylor.jpg',
      name: 'Finbarr Taylor',
      position: 'CEO & co-founder',
    },
  },
  {
    logo: {
      id: logos[BuzzLogoIdEnum.CINDICATOR].src,
    },
    companyName: 'Cindicator',
    href: '/customers/cindicator/',
    paragraph:
      '“Before using Uploadcare, I just did all the image processing myself with scripts. It was a nightmare. It’s a lot more work, and it’s not interesting work.”',
    client: {
      photo: 'https://ucarecdn.com/1c1b5393-893e-4d34-bb65-561134a02de4/',
      name: 'Sergey Bliznetsov',
      position: 'Front End Architect',
    },
  },
];
