import styled, { css } from 'styled-components';
import { Tab } from '@uc-common/tabs';
import { Code } from '@uc-common/code';

import { LinkWithArrow } from '../../../../Typography';
import { hideScrollbarStyles } from '../../../../CommonStyles';

export const APIReferencesContainer = styled.article(
  ({ theme: { tablet } }) => css`
    --link-block-height: 65px;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    background-color: #000000;
    border: 1px solid #21262f;
    border-radius: 12px;
    width: 100%;

    ${tablet()} {
      grid-template-columns: 234px 1fr;
      overflow: hidden;
      height: 530px;
      grid-template-rows: 100%;
    }
  `
);

export const APIReferencesLanguages = styled.div(
  ({ theme: { tablet } }) => css`
    position: relative;
    padding: 10px;

    ${tablet()} {
      padding: 0;
      border-right: 1px solid #21262f;
      height: calc(100% - var(--link-block-height));
    }
  `
);

export const APIReferencesLanguagesExpand = styled.div(
  ({ $isExpanded = false, theme: { tablet } }) => css`
    position: absolute;
    top: 51px;
    left: 7px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4px;
    padding: 10px;
    width: calc(100% - 14px);
    border: 1px solid #21262f;
    border-radius: 12px;
    opacity: 0;
    pointer-events: none;
    background-color: #000000;
    max-height: 300px;
    overflow: auto;
    z-index: 10;
    box-sizing: border-box;
    transition: opacity 0.2s ease-in-out;

    ${hideScrollbarStyles}

    ${$isExpanded &&
    css`
      opacity: 1;
      pointer-events: auto;
    `}

    ${tablet()} {
      top: 0;
      left: 0;
      padding: 17px 19px 10px 17px;
      border: none;
      border-radius: 0;
      opacity: 1;
      pointer-events: auto;
      transition: none;
      max-height: 100%;
      width: 100%;
    }
  `
);

export const tabActiveStyles = css`
  color: #ffffff;
  background-color: #121212;

  &::before {
    opacity: 1;
  }
`;

export const APIReferencesLanguage = styled(Tab)(
  ({ $isActive = false, $iconUUID }) => css`
    --tab-active-color: #ffffff;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 6px;
    font-size: 16px;
    line-height: 1.25;
    color: #6e7c88;
    background-color: #000000;
    padding: 4px 7px;
    margin: 0;
    text-align: left;
    border: none;
    outline: none;
    transition:
      color 0.2s ease-in-out,
      background-color 0.2s ease-in-out;

    ${$iconUUID &&
    css`
      &::before {
        position: static;
        content: '';
        width: 28px;
        height: 28px;
        margin-right: 10px;
        background-color: transparent;
        opacity: 1;
        background-image: url(${`https://ucarecdn.com/${$iconUUID}/-/preview/-/quality/smart/`});
        background-position: center;
        background-repeat: no-repeat;
      }
    `}

    &:hover {
      ${tabActiveStyles}
    }

    ${$isActive &&
    css`
      ${tabActiveStyles}
    `}
  `
);

export const APIReferencesContent = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    padding: 25px 0 25px 20px;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 40px;
    width: 100%;
    height: max-content;
    overflow: hidden;

    ${tablet()} {
      padding: 30px;
      height: 100%;
      overflow-y: auto;
      ${hideScrollbarStyles}
    }

    ${laptop()} {
      padding-right: 58px;
    }
  `
);

export const APIReferencesCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ApiReferencesCodeHeading = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 1.8;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #cdced4;
  padding-bottom: 3px;
  border-bottom: 1px solid #21262f;
`;

export const APIReferencesCode = styled(Code)`
  --default-color: #8c8c8c;
  --background-color: transparent;
  --border-color: none;
  --color-1: #ffffff;
  --color-4: #92b8f0;
  margin-top: 17px;
  width: 100%;

  code {
    padding: 0 10px 0 0;
    font-size: 13px;
  }

  .property {
    color: var(--color-1);
  }

  .url-link {
    color: #3f90ff;
    text-decoration: none;
  }

  .language-json {
    color: var(--color-1);

    .punctuation {
      color: var(--default-color);
    }

    .number {
      color: var(--color-1);
    }
  }
`;

export const APIReferencesLinkContainer = styled.div(
  ({ theme: { tablet } }) => css`
    border-top: 1px solid #21262f;
    padding: 20px 0 20px 20px;

    ${tablet()} {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 234px;
      display: flex;
      box-sizing: border-box;
      border-right: 1px solid #21262f;
      background-color: #000000;
      padding: 22px 28px;
      z-index: 20;
    }
  `
);

export const APIReferencesLink = styled(LinkWithArrow)(
  ({ theme: { laptop } }) => css`
    color: #0baeff;

    ${laptop()} {
      font-size: 15px;
    }
  `
);
