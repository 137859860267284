import styled, { css } from 'styled-components';

export const FeaturesScreenFeatures = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-gap: 45px;
    margin-top: 30px;

    ${tablet()} {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 35px;
      grid-gap: 55px 15px;
    }

    ${laptop()} {
      grid-template-columns: repeat(18, 1fr);
      margin-top: 55px;
    }
  `
);

export const FeaturesScreenFeature = styled.div`
  background-color: #ffffff;
  height: 200px;
  max-width: 545px;
`;
