import styled, { css } from 'styled-components';
import Image from '@uc-common/image';
import { SplitCardHeading, SplitCardParagraph } from '~/src/components/SplitCard';

export const ReleasingCardTitle = styled(SplitCardHeading)(
  ({ theme: { laptop, desktop } }) => css`
    font-size: 28px;
    text-align: center;
    color: #000000;

    ${laptop()} {
      text-align: left;
    }

    ${desktop()} {
      font-size: 34px;
    }
  `
);

export const ReleasingCardText = styled(SplitCardParagraph)`
  width: 100%;
  color: #596570;
`;

export const ReleasingCardItem = styled.div(
  ({ theme: { laptop } }) => css`
    display: grid;
    grid-gap: 15px;

    &:first-child {
      grid-area: first-item;
      grid-gap: 25px;
    }

    &:nth-child(2) {
      grid-area: second-item;
    }

    &:nth-child(3) {
      grid-area: third-item;
    }

    ${laptop()} {
      &:first-child {
        margin-right: 25px;
      }
    }
  `
);

export const ReleasingCardItemIcon = styled(Image)`
  width: auto;
  height: 30px;
`;

export const ReleasingCardItemTitle = styled.h4(
  ({ theme: { laptop } }) => css`
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.5;
    color: #000000;

    ${laptop()} {
      font-size: 16px;
    }
  `
);

export const ReleasingCardItemText = styled.p(
  ({ theme: { laptop } }) => css`
    color: #596570;
    line-height: 1.5;
    font-size: 15px;
    text-align: left;

    ${laptop()} {
      font-size: 16px;
      text-align: start;
    }
  `
);

export const ReleasingCardButtonsContainer = styled.div(
  ({ theme: { tablet } }) => css`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    grid-area: buttons;
    justify-content: center;

    ${tablet()} {
      justify-content: flex-start;
    }
  `
);

export const ReleasingCardContainer = styled.div(
  ({ theme: { tablet, laptop }, $isDark = false }) => css`
    border-radius: 12px;
    padding: 40px 15px;
    display: grid;
    grid-gap: 25px;
    align-items: flex-start;
    background-color: #ffffff;
    box-shadow:
      0 1px 2px rgba(0, 0, 0, 0.16),
      0 4px 18px rgba(0, 0, 0, 0.08);
    grid-template-areas:
      'first-item'
      'second-item'
      'third-item'
      'buttons';

    ${tablet()} {
      padding: 50px 60px;
      grid-gap: 40px;
      grid-template-areas:
        'first-item first-item'
        'second-item third-item'
        'buttons buttons';
    }

    ${laptop()} {
      grid-gap: 25px 30px;
      grid-template-columns: 13fr 7fr 7fr;
      grid-template-areas:
        'first-item second-item third-item'
        'buttons . . ';
    }

    ${$isDark &&
    css`
      background: radial-gradient(
          70.23% 124.92% at 41.16% -20.27%,
          rgba(159, 241, 247, 0.106) 0%,
          rgba(241, 127, 182, 0.053) 50%,
          rgba(188, 175, 216, 0) 100%
        )
        #0f1113;

      ${ReleasingCardTitle} {
        color: #ffffff;
      }

      ${ReleasingCardText} {
        color: #818c98;
      }

      ${ReleasingCardItemTitle} {
        color: #ffffff;
      }

      ${ReleasingCardItemText} {
        color: #818c98;
      }
    `}
  `
);
