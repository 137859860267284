import styled, { css } from 'styled-components';

export const TabsContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0 22px;
  width: 100%;
  box-sizing: border-box;
  overflow-x: auto;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #efefef;
  }
`;

export const PanelsContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 30px 22px 22px 22px;
  background-color: #f8f9fb;
`;

export const TabButton = styled.button(
  ({ $isActive = false, $activeStyles }) => css`
    --tab-active-color: #3771ff;

    position: relative;
    font-weight: 500;
    font-size: 14px;
    color: #626b73;
    transition: color 0.2s;
    cursor: pointer;
    padding: 12px 0;
    margin-left: 25px;
    flex-shrink: 0;
    border: none;
    outline: none;
    background-color: transparent;

    &:first-child {
      margin-left: 0;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--tab-active-color);
      opacity: 0;
      z-index: 30;
      transition: opacity 0.2s ease;
    }

    &:hover {
      color: var(--tab-active-color);
    }

    ${$isActive &&
    css`
      && {
        color: var(--tab-active-color);
        pointer-events: none;

        &::before {
          opacity: 0.5;
        }

        ${$activeStyles};
      }
    `}
  `
);
