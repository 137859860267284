import styled, { css } from 'styled-components';

import { BigHeading } from '../../../Typography';

export const FirstScreenSection = styled.section(
  ({ theme: { tablet, laptop, desktop } }) => css`
    padding: 60px 0 40px;
    background-color: #000000;
    background-image: url('https://ucarecdn.com/06a12f8f-95bf-483b-aa5d-8de885182e8b/');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;

    ${tablet()} {
      padding: 110px 0 50px;
      background-position: center;
    }

    ${laptop()} {
      padding: 160px 0 70px;
    }

    ${desktop()} {
      padding: 200px 0 90px;
    }
  `
);

export const FirstScreenHeading = styled(BigHeading)(
  ({ theme: { tablet, laptop, desktop } }) => css`
    font-size: 30px;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 19px;
    color: #ffffff;
    display: inline-flex;
    flex-direction: column;
    text-align: center;

    ${tablet()} {
      font-size: 56px;
      margin-bottom: 30px;
    }

    ${laptop()} {
      font-size: 60px;
    }

    ${desktop()} {
      font-size: 70px;
      max-width: 850px;
    }
  `
);

export const FirstScreenParagraph = styled.p(
  ({ theme: { tablet, laptop, desktop } }) => css`
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    color: #bbc4d2;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--content-max-width);

    ${tablet()} {
      font-size: 18px;
    }

    ${laptop()} {
      font-size: 20px;
    }

    ${desktop()} {
      font-size: 22px;
      max-width: 860px;
    }
  `
);

export const FirstScreenButtons = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    margin-top: 20px;
    width: 100%;
    display: grid;
    grid-gap: 20px;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, 140px);

    ${tablet()} {
      margin-top: 25px;
      grid-gap: 40px;
      grid-template-columns: repeat(auto-fit, 180px);
    }

    ${laptop()} {
      margin-top: 35px;
    }
  `
);
