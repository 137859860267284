import styled, { css } from 'styled-components';

import { LinkWithArrow } from '~/src/components/Typography';

export const OperationContainer = styled.div(
  ({ theme: { tablet, laptop, desktop } }) => css`
    display: flex;
    flex-direction: column;
    margin-top: 60px;

    &:first-child {
      margin-top: 0;
    }

    ${tablet()} {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      &:first-child {
        flex-direction: row-reverse;
      }
    }

    ${laptop()} {
      margin-top: 80px;
    }

    ${desktop()} {
      margin-top: 100px;
    }
  `
);

export const OperationInfo = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-gap: 15px;
    flex-basis: 50%;
    justify-items: start;

    ${tablet()} {
      max-width: 330px;
      flex-basis: 42%;
      margin-top: 20px;
    }

    ${laptop()} {
      flex-basis: 40%;
      grid-gap: 20px;
      max-width: 370px;
    }
  `
);

export const OperationHeading = styled.h3(
  ({ theme: { laptop, desktop } }) => css`
    font-size: 24px;
    line-height: 130%;
    color: #e7ecf2;

    ${laptop()} {
      font-size: 28px;
    }

    ${desktop()} {
      font-size: 32px;
    }
  `
);

export const OperationParagraph = styled.p(
  ({ theme: { laptop, desktop } }) => css`
    font-size: 14px;
    line-height: 1.6;
    color: #93a0ae;

    ${laptop()} {
      font-size: 16px;
    }

    ${desktop()} {
      font-size: 18px;
    }
  `
);

export const OperationLinkWithArrow = styled(LinkWithArrow)`
  font-size: 17px;
`;

export const OperationExamples = styled.div(
  ({ theme: { tablet, laptop, desktop } }) => css`
    margin-top: 30px;

    ${tablet()} {
      margin-top: 0;
      flex-basis: 55%;
      grid-gap: 12px;
    }

    ${laptop()} {
      grid-gap: 16px;
    }

    ${desktop()} {
      flex-basis: 58%;
      grid-gap: 19px;
    }
  `
);

export const OperationCodeContainer = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr;
  width: 100%;
`;

export const TitleContainer = styled.span`
  color: #cdced4;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  line-height: 1.7;
  margin-left: 20px;
`;

export const CodeAndTitleContainer = styled.div`
  display: grid;
  grid-gap: 5px;
`;
