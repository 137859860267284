import styled, { css } from 'styled-components';
import { Button } from '@uc-common/button';

export const TryCodeBlockContainer = styled.div(
  ({ theme: { tablet, laptop, desktop } }) => css`
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: var(--content-max-width);
    background-color: #111113;
    border: 1px solid #21262f;
    border-radius: 16px;
    padding: 45px 35px 40px 35px;

    ${tablet()} {
      margin-top: 50px;
    }

    ${laptop()} {
      margin-top: 60px;
    }

    ${desktop()} {
      margin-top: 70px;
    }
  `
);

export const TryCodeBlockSuperHeading = styled.span(
  ({ theme: { tablet, laptop } }) => css`
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;

    ${tablet()} {
      font-size: 14px;
    }

    ${laptop()} {
      font-size: 18px;
    }
  `
);

export const TryCodeBlockText = styled.p(
  ({ theme: { tablet, laptop, desktop } }) => css`
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    color: #bbc4d2;
    margin-top: 10px;
    max-width: 580px;

    ${tablet()} {
      font-size: 18px;
      margin-top: 20px;
    }

    ${laptop()} {
      font-size: 20px;
    }

    ${desktop()} {
      font-size: 22px;
    }
  `
);

export const TryCodeBlockButton = styled(Button)(
  ({ theme: { laptop } }) => css`
    margin-top: 25px;
    padding: 5px 35px;

    ${laptop()} {
      margin-top: 35px;
    }
  `
);
