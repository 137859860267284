import React from 'react';

import { SectionWrapper } from '../../CommonStyles';
import { JsUploadSection, JsUploadSubTitle, JsUploadSubText } from '../styles/styled-components';
import { FeaturesScreenFeatures } from './styles/FeaturesScreen.styles';

import { FeatureCard } from './components';

import { FEATURES_CARDS_CONTENT } from './data';

export const FeaturesScreen = () => (
  <JsUploadSection data-navbar-theme="dark" $isDark>
    <SectionWrapper>
      <JsUploadSubTitle>Features</JsUploadSubTitle>
      <JsUploadSubText>
        Uploadcare’s JavaScript File Upload Library offers a wide range of features designed to
        streamline your project and optimize file handling:
      </JsUploadSubText>
      <FeaturesScreenFeatures>
        {FEATURES_CARDS_CONTENT.map(({ className, icon, title, paragraph }, index) => (
          <FeatureCard key={title} id={index + 1} className={className} icon={icon} title={title}>
            {paragraph}
          </FeatureCard>
        ))}
      </FeaturesScreenFeatures>
    </SectionWrapper>
  </JsUploadSection>
);
