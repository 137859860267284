import React from 'react';
import {
  FeatureCardContainer,
  FeatureCardIcon,
  FeatureCardTitle,
  FeatureCardParagraph,
} from './styles/FeatureCard.styles';

export const FeatureCard = ({ id, icon, title, children }) => (
  <FeatureCardContainer>
    <FeatureCardIcon $variant={`${id % 8}`}>{icon}</FeatureCardIcon>
    <FeatureCardTitle>{title}</FeatureCardTitle>
    <FeatureCardParagraph>{children}</FeatureCardParagraph>
  </FeatureCardContainer>
);
