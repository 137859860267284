import React from 'react';
import styled from 'styled-components';
import { ButtonTypeEnum } from '@uc-common/button';
import { Code } from '@uc-common/code';

import { ClientBlocksPageSection } from '../styles/styled-components';
import { OperationBlock } from './components/OperationBlock';
import {
  OperationCodeContainer,
  TitleContainer,
  CodeAndTitleContainer,
} from './components/OperationBlock/styles/OperationBlock.styles';

const ScrollableCode = styled(Code)`
  overflow-y: auto;
  width: 100%;
  --border-color: #21262f;
  --background-color: #000000;
  --default-color: #9cdcfe;
  --color-1: #ce9178;
  --color-2: #c586c0;
  --color-3: #6a9955;
  --color-4: #dcdcaa;
  --color-5: #4ec9b0;
`;

const DarkThemeCode = styled(ScrollableCode)`
  code {
    &[class*='language-'] {
      & .operator {
        color: var(--color-2);
      }

      & .parameter {
        color: #ffab70;
      }
    }
  }
`;

export const UploadClientBlock = () => (
  <OperationBlock
    heading="Uploadcare Upload Client"
    paragraph="An Upload API wrapper to work with Node.js and browser"
    link={{
      url: 'https://uploadcare.github.io/uploadcare-js-api-clients/upload-client/',
      text: 'Learn more',
    }}
  >
    <OperationCodeContainer>
      <CodeAndTitleContainer>
        <TitleContainer>Install</TitleContainer>
        <ScrollableCode code="npm install @uploadcare/upload-client" />
      </CodeAndTitleContainer>

      <CodeAndTitleContainer>
        <TitleContainer>Usage</TitleContainer>
        <DarkThemeCode
          copyButton={{
            type: ButtonTypeEnum.PRIMARY,
          }}
          language="js"
          beautifyOptions={{
            brace_style: 'preserve-inline',
          }}
          code={`
            import { UploadClient } from '@uploadcare/upload-client'

            const client = new UploadClient({ publicKey: 'YOUR_PUBLIC_KEY' })
        `}
        />
      </CodeAndTitleContainer>
    </OperationCodeContainer>
  </OperationBlock>
);

export const RestApiClientBlock = () => (
  <OperationBlock
    heading="Uploadcare REST API Client"
    paragraph="A REST API wrapper to work with Node.js and browser"
    link={{
      url: 'https://uploadcare.github.io/uploadcare-js-api-clients/rest-client/',
      text: 'Learn more',
    }}
  >
    <OperationCodeContainer>
      <CodeAndTitleContainer>
        <TitleContainer>Install</TitleContainer>
        <ScrollableCode code="npm install @uploadcare/rest-client" />
      </CodeAndTitleContainer>

      <CodeAndTitleContainer>
        <TitleContainer>Usage</TitleContainer>
        <DarkThemeCode
          copyButton={{
            type: ButtonTypeEnum.PRIMARY,
          }}
          language="js"
          beautifyOptions={{
            brace_style: 'preserve-inline',
          }}
          code={`
            import { listOfFiles, UploadcareSimpleAuthSchema } from '@uploadcare/rest-client';

            const uploadcareSimpleAuthSchema = new UploadcareSimpleAuthSchema({
              publicKey: 'YOUR_PUBLIC_KEY',
              secretKey: 'YOUR_SECRET_KEY',
            });

            const result = await listOfFiles({}, { authSchema: uploadcareSimpleAuthSchema })
        `}
        />
      </CodeAndTitleContainer>
    </OperationCodeContainer>
  </OperationBlock>
);

export const ClientBlocks = () => (
  <ClientBlocksPageSection>
    <UploadClientBlock />
    <RestApiClientBlock />
  </ClientBlocksPageSection>
);
