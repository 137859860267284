import React from 'react';
import styled, { css } from 'styled-components';

export const getCircleVariant = (variant) => {
  switch (variant) {
    case '1':
      return {
        bg: css`
          background: radial-gradient(141.01% 172.5% at 0% 0%, #251061 0%, #3f90ff 100%);
        `,
        txt: css`
          background: radial-gradient(141.01% 172.5% at 0% 0%, #4924b1 0%, #5ca1ff 84.45%);
        `,
      };
    case '2':
      return {
        bg: css`
          background: radial-gradient(141.01% 172.5% at 0% 0%, #251061 0%, #9365fd 100%);
        `,
        txt: css`
          background: radial-gradient(141.01% 172.5% at 0% 0%, #371b88 0%, #9365fd 84.45%);
        `,
      };
    case '3':
      return {
        bg: css`
          background: radial-gradient(141.01% 172.5% at 0% 0%, #6f4392 0%, #ec5adc 100%);
        `,
        txt: css`
          background: radial-gradient(141.01% 172.5% at 0% 0%, #b951be 0%, #9365fd 84.45%);
        `,
      };
    default:
      return {};
  }
};

const Circle = styled.div(
  ({ theme: { laptop }, $variant }) => css`
    border-radius: 50%;
    color: #ffffff;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    ${getCircleVariant($variant).bg};

    ${laptop()} {
      width: 40px;
      height: 40px;
      margin-bottom: 15px;
    }
  `
);

const Text = styled.span(
  ({ theme: { tablet, laptop }, $variant }) => css`
    font-weight: 700;
    font-size: 12px;
    line-height: 130%;
    text-transform: uppercase;
    background-size: 100%;
    text-align: center;

    ${getCircleVariant($variant).txt};
    /* stylelint-disable-next-line */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    ${tablet()} {
      font-size: 14px;
    }

    ${laptop()} {
      font-size: 18px;
    }
  `
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Step = ({ step, children }) => (
  <Container>
    <Circle $variant={step}>{step}</Circle>
    <Text $variant={step}>{children}</Text>
  </Container>
);
