import styled, { css } from 'styled-components';
import { JsUploadSection } from '../../styles/styled-components';
import { Link } from '../../../Link';

export const GetStartedScreenClientBlockWrapper = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    margin-top: 35px;

    ${tablet()} {
      margin-top: 45px;
    }

    ${laptop()} {
      margin-top: 55px;
    }
  `
);

export const GetStartedScreenStepText = styled.p(
  ({ theme: { laptop, desktop } }) => css`
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: #93a0ae;
    letter-spacing: -0.025em;
    display: block;
    font-weight: 300;
    margin-top: 20px;
    max-width: var(--content-max-width);
    margin-left: auto;
    margin-right: auto;

    ${laptop()} {
      font-size: 18px;
      margin-top: 25px;
    }

    ${desktop()} {
      font-size: 20px;
    }
  `
);

export const GetStartedScreenStepContainer = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    margin-top: 60px;

    ${tablet()} {
      margin-top: 75px;
    }

    ${laptop()} {
      margin-top: 90px;
    }
  `
);

export const GetStartedScreenFeaturesWrapper = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    margin-top: 50px;
    display: grid;
    grid-gap: 40px;

    ${tablet()} {
      margin-top: 60px;
    }

    ${laptop()} {
      margin-top: 70px;
    }
  `
);

export const GetStartedScreenFrameworksContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 700px;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
`;

export const GetStartedScreenFramework = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding-left: 20px;
  padding-right: 25px;
  border-radius: 8px;
  color: #93a0ae;
  transition:
    color 0.2s ease-in,
    background-color 0.2s ease-in;

  &:hover {
    background-color: #151719;
    color: #ffffff;
  }
`;

export const GetStartedSection = styled(JsUploadSection)(
  ({ theme: { tablet, laptop, desktop } }) => css`
    padding-bottom: 60px;

    ${tablet()} {
      padding-bottom: 65px;
    }

    ${laptop()} {
      padding-bottom: 85px;
    }

    ${desktop()} {
      padding-bottom: 100px;
    }
  `
);

export const GetStartedInlineLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid;
  border-color: transparent;
  transition: border-color 0.1s ease-in;

  &:hover {
    border-color: #596570;
  }
`;
