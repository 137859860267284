import React from 'react';
import { useClickAway, useKey } from 'react-use';

import { MobileExpandButtonContainer } from './styles/MobileExpandButton.styles';

export const MobileExpandButton = ({
  icon,
  isExpanded = false,
  setIsExpanded,
  contentRef,
  children,
}) => {
  const hide = () => setIsExpanded(false);
  useKey('Escape', hide);
  useClickAway(contentRef, hide);

  return (
    <MobileExpandButtonContainer
      as="button"
      onClick={() => setIsExpanded(!isExpanded)}
      $iconUUID={icon}
      $isActive
    >
      {children}
    </MobileExpandButtonContainer>
  );
};
