import React from 'react';
import { JsUploadSection } from '../styles/styled-components';
import { ImageProcessingClientCases } from '../ImageProcessingClientCases';

export const ReviewsScreen = () => (
  <JsUploadSection>
    <ImageProcessingClientCases
      className="light-background"
      heading="Reviews"
      description=" Developers and businesses worldwide rely on Uploadcare’s JavaScript File
  Upload Library for their file handling needs. Here is what our satisfied
  customers have to say about their experience with Uploadcare."
    />
  </JsUploadSection>
);
