import styled from 'styled-components';

import { APIReferencesLanguage } from '../../../styles/APIReferences.styles';

export const MobileExpandButtonContainer = styled(APIReferencesLanguage)`
  &::after {
    content: '';
    border: solid #e7ecf2;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    margin-left: auto;
    margin-right: 7px;
    transform: rotate(45deg);
  }
`;
