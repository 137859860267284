import styled, { css } from 'styled-components';
import { Button } from '@uc-common/button';
import Image from '@uc-common/image';
import { Link } from '../../Link';

import { APIReferences } from '../../forAPI/components/APIReferences';

export const FileUploadLibraryParagraph = styled.p(
  ({ theme: { laptop } }) => css`
    font-size: 15px;
    line-height: 1.6;
    color: #93a0ae;

    ${laptop()} {
      font-size: 16px;
    }
  `
);

export const FileUploadLibraryList = styled.ul(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;

    ${tablet()} {
      grid-gap: 20px;
    }

    ${laptop()} {
      grid-gap: 24px;
    }
  `
);

export const FileUploadLibraryListItem = styled.li(
  ({ theme: { laptop } }) => css`
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.6;
    color: #ffffff;

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-width: 4.5px 0 4.5px 8px;
      border-color: transparent transparent transparent #0baeff;
      border-style: solid;
      margin-right: 20px;
    }

    ${laptop()} {
      font-size: 17px;
    }
  `
);

export const FileUploadLibraryButton = styled(Button)`
  min-width: 168px;
`;

export const FileUploadLibrarySectionReferences = styled(APIReferences)(
  ({ theme: { laptop, desktop } }) => css`
    margin-top: 60px;

    ${laptop()} {
      margin-top: 66px;
    }

    ${desktop()} {
      margin-top: 71px;
    }
  `
);

export const JsUploadHeading = styled.h3(
  ({ theme: { tablet, laptop } }) => css`
    font-size: 28px;
    line-height: 1.2;
    letter-spacing: -0.025em;
    color: #e7ecf2;
    margin-bottom: 25px;
    text-align: center;
    max-width: 450px;

    ${tablet()} {
      margin-bottom: 25px;
      max-width: 450px;
    }

    ${laptop()} {
      margin-bottom: 40px;
      max-width: 650px;
      font-size: 40px;
    }
  `
);

export const FileUploadLibraryAdvantagesList = styled.ul(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 40px;

    ${tablet()} {
      margin-top: 10px;
      grid-template-columns: 1fr 1fr;
      grid-gap: 50px 64px;
    }

    ${laptop()} {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 30px;
    }
  `
);

export const FileUploadLibraryAdvantagesListItem = styled.li`
  display: flex;
  flex-direction: column;
`;

export const FileUploadLibraryAdvantagesListItemIcon = styled(Image)(
  ({ theme: { laptop } }) => css`
    width: 30px;
    height: 30px;
    margin-bottom: 20px;

    ${laptop()} {
      margin-bottom: 30px;
    }
  `
);

export const FileUploadLibraryAdvantagesListItemHeading = styled.h4(
  ({ theme: { laptop, desktop } }) => css`
    font-weight: 500;
    font-size: 16px;
    line-height: 1.3;
    color: #ffffff;

    ${laptop()} {
      font-size: 18px;
    }

    ${desktop()} {
      font-size: 20px;
    }
  `
);

export const FileUploadLibraryAdvantagesListItemParagraph = styled(FileUploadLibraryParagraph)(
  ({ theme: { laptop, desktop } }) => css`
    font-size: 14px;
    line-height: 1.5;
    margin-top: 7px;

    ${laptop()} {
      font-size: 16px;
    }

    ${desktop()} {
      font-size: 18px;
    }
  `
);

export const FileUploadLibraryAdvantagesHeadingContainer = styled.div(
  ({ theme: { tablet } }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${tablet()} {
      display: block;

      ${JsUploadHeading} {
        text-align: left;
      }
    }
  `
);

export const JsUploadDescription = styled.span(
  ({ theme: { tablet, laptop, desktop } }) => css`
    font-size: 14px;
    line-height: 1.7;
    letter-spacing: 0.05em;
    color: #838f9a;
    margin-bottom: 10px;
    text-align: left;
    display: block;
    font-weight: 300;

    ${tablet()} {
      font-size: 16px;
      margin-bottom: 15px;
    }

    ${laptop()} {
      font-size: 18px;
      margin-bottom: 9px;
    }

    ${desktop()} {
      font-size: 20px;
    }
  `
);

export const Subtitle = styled.span(
  ({ theme: { desktop } }) => css`
    font-size: 22px;
    font-weight: 700;
    line-height: 1.3;
    text-align: left;
    color: #ffffff;
    letter-spacing: -0.025em;
    display: block;

    ${desktop()} {
      font-size: 25px;
    }
  `
);

export const ClientBlocksPageSection = styled.section(
  ({ theme: { laptop, desktop } }) => css`
    margin-top: 50px;
    width: 100%;

    ${laptop()} {
      margin-top: 65px;
    }

    ${desktop()} {
      margin-top: 90px;
    }
  `
);
export const JsUploadLinksContainer = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    grid-row-gap: 15px;
    grid-column-gap: 10px;
    margin-top: 10px;
    width: 100%;

    ${tablet()} {
      grid-template-columns: repeat(2, minmax(168px, max-content));
      justify-content: center;
    }

    ${laptop()} {
      margin-top: 40px;
    }
  `
);

export const JsUploadSubTitle = styled.h2(
  ({ theme: { tablet, laptop } }) => css`
    color: #000000;
    font-size: 28px;
    font-weight: 700;
    line-height: 120%;
    margin: 50px auto 20px;
    max-width: var(--content-max-width);
    text-align: left;

    ${tablet()} {
      font-size: 38px;
      line-height: 1.3;
    }

    ${laptop()} {
      font-size: 42px;
    }
  `
);

export const JsUploadSubText = styled.p(
  ({ theme: { tablet, laptop, desktop } }) => css`
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    color: #454f58;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--content-max-width);

    ${tablet()} {
      font-size: 18px;
    }

    ${laptop()} {
      font-size: 20px;
    }

    ${desktop()} {
      font-size: 22px;
    }
  `
);

export const JsUploadSection = styled.section(
  ({ theme: { tablet, laptop, desktop }, $isDark }) => css`
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #f6f7f8;

    &:nth-last-of-type(1) {
      padding-bottom: 75px;
    }

    ${$isDark &&
    css`
      background-color: #000000;

      ${JsUploadSubTitle} {
        color: #ffffff;
      }

      ${JsUploadSubText} {
        color: #bbc4d2;
      }
    `}

    ${tablet()} {
      padding-top: 35px;
      padding-bottom: 15px;

      &:nth-last-of-type(1) {
        padding-bottom: 85px;
      }
    }

    ${laptop()} {
      padding-top: 45px;
      padding-bottom: 15px;

      &:nth-last-of-type(1) {
        padding-bottom: 95px;
      }
    }

    ${desktop()} {
      padding-top: 60px;
      padding-bottom: 20px;

      &:nth-last-of-type(1) {
        padding-bottom: 140px;
      }
    }
  `
);

export const JsUploadInlineLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid;
  border-color: transparent;
  transition: border-color 0.1s ease-in;

  &:hover {
    border-color: #596570;
  }
`;
