import React from 'react';

import { JsUploadInlineLink } from '../styles/styled-components';

import { ReactComponent as WorldBoxedSvg } from './assets/world-boxed.svg';
import { ReactComponent as ShieldBoxedSvg } from './assets/shield-boxed.svg';
import { ReactComponent as RocketBoxedSvg } from './assets/rocket-boxed.svg';
import { ReactComponent as LightningBoxedSvg } from './assets/lightning-boxed.svg';
import { ReactComponent as IntelligenceBoxedSvg } from './assets/intelligence-boxed.svg';
import { ReactComponent as CheckmarkGearBoxedSvg } from './assets/checkmark-gear-boxed.svg';
import { ReactComponent as ArrowsBoxedSvg } from './assets/arrows-boxed.svg';
import { ReactComponent as AdaptiveDeliveryBoxedSvg } from './assets/adaptive-delivery-boxed.svg';

export const FEATURES_CARDS_CONTENT = [
  {
    icon: <WorldBoxedSvg />,
    title: 'Integrated Cloud Storage',
    paragraph:
      'Enjoy seamless integration with popular cloud storage providers, making' +
      ' it easy to store and manage your files in the cloud.',
  },
  {
    icon: <IntelligenceBoxedSvg />,
    title: 'File Moderation',
    paragraph: (
      <>
        <JsUploadInlineLink to="/docs/moderation/">Automate</JsUploadInlineLink> content moderation
        and maintain control over user-generated content by screening uploaded files for
        inappropriate or harmful material.
      </>
    ),
  },
  {
    icon: <CheckmarkGearBoxedSvg />,
    title: 'Malware Protection',
    paragraph:
      'Secure your application and users by scanning uploaded files for' +
      ' malware and blocking any suspicious files before they reach your' +
      ' system.',
  },
  {
    icon: <AdaptiveDeliveryBoxedSvg />,
    title: 'File Validation',
    paragraph: (
      <>
        Ensure only the right files are uploaded with customizable{' '}
        <JsUploadInlineLink to="/docs/file-uploader-api/file-validation/">
          file validation settings
        </JsUploadInlineLink>
        , such as size and format restrictions.
      </>
    ),
  },
  {
    icon: <LightningBoxedSvg />,
    title: 'Accelerated Upload',
    paragraph:
      'Experience lightning-fast file uploads with our intelligent network' +
      ' routing and automatic file compression.',
  },
  {
    icon: <RocketBoxedSvg />,
    title: 'Storage and CDN',
    paragraph:
      'Efficiently store and deliver your files globally with Uploadcare’s' +
      ' reliable storage solution and high-performance content delivery' +
      ' network (CDN).',
  },
  {
    icon: <ShieldBoxedSvg />,
    title: 'Security',
    paragraph: (
      <>
        Keep your files safe with our secure infrastructure, SSL encryption, and advanced access
        controls. <JsUploadInlineLink to="/docs/security/">Signed uploads</JsUploadInlineLink> work
        for a particular Uploadcare project.
      </>
    ),
  },
  {
    icon: <ArrowsBoxedSvg />,
    title: 'Multipart File Upload',
    paragraph:
      'Upload large files efficiently with our multipart file upload feature,' +
      ' which breaks files into smaller chunks and uploads them concurrently' +
      ' for faster and more reliable uploads.',
  },
];
