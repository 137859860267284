import styled, { css } from 'styled-components';

export const FeatureCardIcon = styled.div`
  height: 60px;
  width: 60px;
  position: absolute;
  top: -25px;
  border-radius: 16px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FeatureCardContainer = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    border: 1px solid #21262f;
    border-radius: 12px;
    padding: 60px 20px 35px;
    position: relative;

    ${tablet()} {
      padding: 60px 30px 35px;
    }

    ${laptop()} {
      &:nth-child(10n + 1) {
        grid-column-start: 1;
        grid-column-end: 7;

        ${FeatureCardIcon} {
          background: radial-gradient(138.24% 138.24% at 13.77% 11.03%, #005a81 0%, #00ffff 100%);
        }
      }

      &:nth-child(10n + 2) {
        grid-column-start: 7;
        grid-column-end: 13;

        ${FeatureCardIcon} {
          background: radial-gradient(138.24% 138.24% at 13.77% 11.03%, #4f038b 0%, #e03bc6 100%);
        }
      }

      &:nth-child(10n + 3) {
        grid-column-start: 13;
        grid-column-end: 19;

        ${FeatureCardIcon} {
          background: radial-gradient(138.24% 138.24% at 13.77% 11.03%, #37038b 0%, #973be0 100%);
        }
      }

      &:nth-child(10n + 4) {
        grid-column-start: 4;
        grid-column-end: 10;

        ${FeatureCardIcon} {
          background: radial-gradient(138.24% 138.24% at 13.77% 11.03%, #26038b 0%, #843be0 100%);
        }
      }

      &:nth-child(10n + 5) {
        grid-column-start: 10;
        grid-column-end: 16;

        ${FeatureCardIcon} {
          background: radial-gradient(138.24% 138.24% at 13.77% 11.03%, #290aa4 0%, #7a3be0 100%);
        }
      }

      &:nth-child(10n + 6) {
        grid-column-start: 1;
        grid-column-end: 7;

        ${FeatureCardIcon} {
          background: radial-gradient(138.24% 138.24% at 13.77% 11.03%, #03218b 0%, #6e56ff 100%);
        }
      }

      &:nth-child(10n + 7) {
        grid-column-start: 7;
        grid-column-end: 13;

        ${FeatureCardIcon} {
          background: radial-gradient(138.24% 138.24% at 13.77% 11.03%, #03398b 0%, #3b7de0 100%);
        }
      }

      &:nth-child(10n + 8) {
        grid-column-start: 13;
        grid-column-end: 19;

        ${FeatureCardIcon} {
          background: radial-gradient(138.24% 138.24% at 13.77% 11.03%, #03428b 0%, #3bc2e0 100%);
        }
      }

      &:nth-child(10n + 9) {
        grid-column-start: 4;
        grid-column-end: 10;

        ${FeatureCardIcon} {
          background: radial-gradient(138.24% 138.24% at 13.77% 11.03%, #26038b 0%, #843be0 100%);
        }
      }

      &:nth-child(10n + 10) {
        grid-column-start: 10;
        grid-column-end: 16;

        ${FeatureCardIcon} {
          background: radial-gradient(138.24% 138.24% at 13.77% 11.03%, #290aa4 0%, #7a3be0 100%);
        }
      }
    }
  `
);

export const FeatureCardTitle = styled.h3`
  color: #ffffff;
  font-size: 18px;
`;

export const FeatureCardParagraph = styled.div`
  color: #93a0ae;
  font-size: 16px;
  margin-top: 10px;
`;
