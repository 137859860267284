import React from 'react';

import {
  MoreInfoScreenHeadingContainer,
  MoreInfoScreenSmallTitle,
  MoreInfoScreenListItemContainer,
  MoreInfoScreenListItemParagraph,
} from './styles/MoreInfoScreenListItem.styles';
import { Subtitle } from '../styles/styled-components';

export const MoreInfoScreenListItem = ({
  isPurple = false,
  isBlue = false,
  smallTitle,
  title,
  children,
}) => (
  <MoreInfoScreenListItemContainer>
    <MoreInfoScreenHeadingContainer>
      <MoreInfoScreenSmallTitle $isPurple={isPurple} $isBlue={isBlue}>
        {smallTitle}
      </MoreInfoScreenSmallTitle>
      <Subtitle>{title}</Subtitle>
    </MoreInfoScreenHeadingContainer>
    <MoreInfoScreenListItemParagraph>{children}</MoreInfoScreenListItemParagraph>
  </MoreInfoScreenListItemContainer>
);
