import React from 'react';
import { ThemesEnum } from '@uc-common/theme';
import { CommonLayout } from '~/src/components/Layout/CommonLayout';
import { Navbar } from '~/src/components/Navbar';
import { Footer } from '~/src/components/Footer';
import {
  FirstScreen,
  FeaturesScreen,
  GetStartedScreen,
  ReviewsScreen,
  CasesScreen,
  ReleasingScreen,
} from '~/src/components/forJsFileUploadLibrary';

export const frontmatter = {
  title: 'JavaScript File Upload Library — Uploadcare',
  description:
    'Enable rock-solid and secure uploads at lightspeed. Integrate a powerful, secure, and customizable' +
    ' file uploader into your web application with Uploadcare’s JavaScript File Upload Library.' +
    ' Boost your project’s performance and enhance user experience with our easy-to-use, feature-rich solution.',
};

const JsUpload = ({ location }) => (
  <CommonLayout meta={frontmatter} pathName={location.pathname} isDark>
    <Navbar initialTheme={ThemesEnum.DARK} />

    <FirstScreen />
    <FeaturesScreen />
    <GetStartedScreen />
    <ReviewsScreen />
    <CasesScreen />
    <ReleasingScreen />

    <Footer />
  </CommonLayout>
);

export default JsUpload;
