import React, { useState, createContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

export const TabsContext = createContext({});

export const Tabs = ({ children, defaultTab, tab }) => {
  const [activeTab, setActiveTab] = useState(defaultTab || tab);

  useEffect(() => {
    tab && setActiveTab(tab);
  }, [tab]);

  const value = useMemo(() => ({ activeTab, setActiveTab }), [activeTab, setActiveTab]);

  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
};

Tabs.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  defaultTab: PropTypes.string,
  tab: PropTypes.string,
};
