import { useContext } from 'react';
import PropTypes from 'prop-types';

import { TabsContext } from './Tabs';

export const Panel = ({ children, tab }) => {
  const { activeTab } = useContext(TabsContext);
  const isActive = activeTab === tab;
  return isActive ? children : null;
};

Panel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  tab: PropTypes.string.isRequired,
};
