import React from 'react';

import {
  OperationContainer,
  OperationExamples,
  OperationHeading,
  OperationInfo,
  OperationLinkWithArrow,
  OperationParagraph,
} from './styles/OperationBlock.styles';

export const OperationBlock = ({ className, children, heading, paragraph, link }) => (
  <OperationContainer className={className}>
    <OperationInfo>
      <div>
        <OperationHeading>{heading}</OperationHeading>
      </div>
      <OperationParagraph>{paragraph}</OperationParagraph>
      <OperationLinkWithArrow to={link.url} target="_blank">
        {link.text}
      </OperationLinkWithArrow>
    </OperationInfo>
    <OperationExamples>{children}</OperationExamples>
  </OperationContainer>
);
