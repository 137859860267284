import React from 'react';
import { JsUploadSubTitle, JsUploadSubText, JsUploadInlineLink } from '../styles/styled-components';
import {
  GetStartedSection,
  GetStartedScreenClientBlockWrapper,
  GetStartedScreenStepText,
  GetStartedScreenStepContainer,
  GetStartedScreenFrameworksContainer,
  GetStartedScreenFramework,
  GetStartedScreenFeaturesWrapper,
} from './styles/GetStartedScreen.styles';
import { SectionWrapper } from '../../CommonStyles';
import { UploadClientBlock } from '../ClientBlock/ClientBlocks';
import { Step } from '../../forMain/Step';
import { MoreInfoScreenListItem } from '../MoreInfoScreenListItem';
import { ReactComponent as ReactLogo } from '../../forFileUploader/MainScreen/components/SupportedFrameworks/assets/react-logo.svg';
import { ReactComponent as VueJsLogo } from '../../forFileUploader/MainScreen/components/SupportedFrameworks/assets/vue-js-logo.svg';
import { ReactComponent as AngularLogo } from '../../forFileUploader/MainScreen/components/SupportedFrameworks/assets/angular-logo.svg';
import { ReactComponent as SvelteLogo } from '../../forFileUploader/MainScreen/components/SupportedFrameworks/assets/svelte-logo.svg';
import { TryCodeBlock } from './TryCodeBlock/TryCodeBlock';

export const GetStartedScreen = () => (
  <GetStartedSection data-navbar-theme="dark" $isDark>
    <SectionWrapper>
      <JsUploadSubTitle>How to Get Started</JsUploadSubTitle>
      <JsUploadSubText>
        Setting up the Uploadcare JavaScript File Upload Library is a straightforward process. To
        ensure seamless integration with your project, follow these steps:
      </JsUploadSubText>

      <GetStartedScreenStepContainer>
        <Step step="1">Installation</Step>
        <GetStartedScreenStepText>
          Begin by adding the Uploadcare library to your project. You can either include the library
          directly from the CDN or install it using a package manager like npm or yarn. Our
          comprehensive{' '}
          <JsUploadInlineLink to="/docs/file-uploader-api/files-uploads/" target="_blank">
            documentation
          </JsUploadInlineLink>{' '}
          provides detailed instructions on installing and configuring the library to suit your
          project’s needs.
        </GetStartedScreenStepText>
        <GetStartedScreenClientBlockWrapper>
          <UploadClientBlock />
        </GetStartedScreenClientBlockWrapper>

        <TryCodeBlock />
      </GetStartedScreenStepContainer>

      <GetStartedScreenStepContainer>
        <Step step="2">Integrations with popular JS Frameworks</Step>
        <GetStartedScreenStepText>
          Uploadcare’s JavaScript File Upload Library is designed to work seamlessly with various
          web development frameworks and libraries and is completely framework-agnostic.
        </GetStartedScreenStepText>
        <GetStartedScreenFrameworksContainer>
          <GetStartedScreenFramework
            target="_blank"
            to="https://github.com/uploadcare/uc-blocks-examples/tree/main/examples/react-uploader"
            data-analytics="btn_get-started-screen_react-uploader"
          >
            <ReactLogo />
          </GetStartedScreenFramework>

          <GetStartedScreenFramework
            target="_blank"
            to="https://github.com/uploadcare/uc-blocks-examples/tree/main/examples/vue-uploader"
            data-analytics="btn_get-started-screen_vue-uploader"
          >
            <VueJsLogo />
          </GetStartedScreenFramework>

          <GetStartedScreenFramework
            target="_blank"
            to="https://github.com/uploadcare/uc-blocks-examples/tree/main/examples/angular-uploader"
            data-analytics="btn_get-started-screen_angular-uploader"
          >
            <AngularLogo />
          </GetStartedScreenFramework>

          <GetStartedScreenFramework
            target="_blank"
            to="https://github.com/uploadcare/uc-blocks-examples/tree/main/examples/svelte-uploader"
            data-analytics="btn_get-started-screen_svelte-uploader"
          >
            <SvelteLogo />
          </GetStartedScreenFramework>
        </GetStartedScreenFrameworksContainer>

        <GetStartedScreenFeaturesWrapper>
          <MoreInfoScreenListItem
            isPurple
            smallTitle="Platform"
            title="Handle all files smart & RESTfully"
          >
            Ship faster with a complete file platform in your toolbelt. Use integrated apps to
            validate, manage and analyze files. Create custom workflows and sprocess in batch via
            REST API.
          </MoreInfoScreenListItem>
          <MoreInfoScreenListItem
            isBlue
            smallTitle="Uploading"
            title="Enable obsessively friendly file uploads"
          >
            Friendly to users and developers alike. Our HTML5 uploading widget and underlying API
            fit your stack, tick every box, and look great on any device.
          </MoreInfoScreenListItem>
        </GetStartedScreenFeaturesWrapper>
      </GetStartedScreenStepContainer>
    </SectionWrapper>
  </GetStartedSection>
);
