import React from 'react';
import { JsUploadSection } from '../styles/styled-components';
import { SectionWrapper } from '../../CommonStyles';
import { ReleasingCard } from './components/ReleasingCard';

export const ReleasingScreen = () => (
  <JsUploadSection>
    <SectionWrapper>
      <ReleasingCard />
    </SectionWrapper>
  </JsUploadSection>
);
